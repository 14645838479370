.features {
  position: relative;
  margin-top: -275px;
  padding-top: 318px;
  padding-bottom: 100px;
  overflow: hidden;
  background: linear-gradient(175deg, #dce4f500, #dce4f500 22%, #dce4f5 22%, #dce4f5, #dce4f5 80%,
    #dce4f500 100%);
  @include media("<=810px") {
    
  }
  
}

.features__inner {
  display: flex;
    align-items: center;
    gap: 24px;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    z-index: 3;
    @include media("<=1440px") {
      max-width: 830px;
      margin: 0 auto;
    }
        @include media("<=1080px") {
          gap: 16px;
        }
        @include media("<=500px") {
          gap: 8px;
        }
}

.features__item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 340px;
  height: 278px;
  flex: 0 1 100%;
  padding: 40px;
  text-align: left;
  border-radius: 10px;
    border: 1px solid #FFF;
    background: linear-gradient(122deg, #FFF 0%, #F5F1FD 96.81%);
  transition: all 0.8s ease 0.2s;
  @include media("<=1440px") {
    max-width: 260px;
    height: 191px;
    padding: 24px;
  }
  @include media("<=1080px") {
 
  }
  
  @include media("<=860px") {
    max-width: 195px;
      height: 178px;
      padding: 20px;
      flex: 0 1 31%;
  }
  @include media("<=500px") {
    flex: 0 1 48%;
  }
 @include media("<=360px") {
   max-width: 140px;
   height: 141px;
   padding: 16px;
 }
  
}

 .features__icon {
   max-width: 60px;
   width: 100%;
   margin-bottom: 24px;

   @include media("<=1440px") {
     margin-bottom: 16px;
     max-width: 44px;
   }

   @include media("<=810px") {
     
   }

   @include media("<=360px") {
     max-width: 36px;
   }

   img {
     width: 100%;
     display: block;
   }
 }

.features__title {
  color: #252432;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 8px;
@include media("<=1440px") {
  font-size: 24px;
}
  @include media("<=1280px") {
    font-size: 18px;
  }

  @include media("<=360px") {
    font-size: 16px;
  }
}

.features__text {
  color: #8987A1;
    font-size: 20px;
    font-weight: 500;
    line-height: 120%;
@include media("<=1440px") {
    font-size: 16px;
  }
  @include media("<=860px") {
    font-size: 14px;

  }

  @include media("<=360px") {
    font-size: 10px;
  }
}