.hero {
  padding-top: 30px;
 background-color: #0CF223;
  position: relative;
//  z-index: 2;
  overflow-x: clip;
  @include media("<=1440px") {
    padding-top: 40px;
  }
  @include media("<=810px") {
    padding-top: 24px;
    
  }
  @include media("<=430px") {
    padding-top: 19px;
  }
  
}

.hero__inner {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  @include media("<=1080px") {
    max-width: 864px;
    margin: 0 auto;
  }
  @include media("<=810px") {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
  }
  @include media("<=430px") {
    
  }
  @include media("<=375px") {
  }
  @include media("<=320px") {
  }
}

.hero__content {
  flex: 1 0 100%;
  max-width: 751px;
  z-index: 24;
  display: flex;
  flex-direction: column;
  margin-right: -31px;
  padding-top: 30px;
  @include media("<=1440px") {
    max-width: 662px;
    padding-top: 0;
  }
  @include media("<=1080px") {
 
    
  }
  @include media("<=810px") {
    align-items: center;
    text-align: center;
    max-width: 560px;
    padding-top: 0;
    margin-right: 0;
    margin-bottom: 16px;
  }
    @include media("<=430px") {
      margin-bottom: 10px;
    }
}

.hero__image {
  flex: 0 1 100%;
  max-width: 779px;
  position: relative;
  margin-right: -31px;
  left: -73px;
  opacity: 0;
  transition: all 0.8s ease 0.6s;

  &._active {
    opacity: 1;
  }

  @include media("<=1440px") {
    max-width: 586px;
    left: -20%;
    right: 0;
    padding-top: 48px;
    flex: 1 0 100%;
  }

  @include media("<=1080px") {
    max-width: 507px;
    padding-top: 94px;
    left: -27%;
  }

  @include media("<=810px") {
    max-width: 579px;
    margin-top: 0;
    margin-right: 0;
    left: 3%;
    justify-content: center;
    padding-top: 0;
  }

  @include media("<=430px") {
    max-width: 380px;
  }

  @include media("<=350px") {}

  img {
    width: 100%;
    display: block;
  }

}

.hero__title {
  font-family: 'Integral CF';
  font-size: 96px;
  text-transform: uppercase;
  margin-bottom: 32px;
  font-weight: 800;
  width: 100%;
  opacity: 0;
  transition: all 0.8s ease 0.2s;
  
    &._active {
      opacity: 1;
    }
        @include media("<=1440px") {
          font-size: 84px;
        }
  @include media("<=1280px") {
    
  }

  @include media("<=1080px") {
    
  }
  @include media("<=810px") {
    text-align: center;
    margin-bottom: 20px;
    font-size: 48px;
  }
  @include media("<=550px") {
    font-size: 36px;
  }

  @include media("<=410px") {
    font-size: 28px;
  }
  img {
    width: 100%;
    display: block;
   
  }
}

.hero__subtitle {
  color: #000;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 47px;
  max-width: 563px;
  line-height: 1.2;
  opacity: 0;
    transition: all 0.8s ease 0.4s;
  
    &._active {
      opacity: 1;
    }
    @include media("<=1440px") {
      font-size: 20px;
      max-width: 411px;
      margin-bottom: 36px;
    }
  @include media("<=1280px") {
    
  }
  @include media("<=1080px") {
   margin-bottom: 32px;
   max-width: 280px;
  }
  @include media("<=810px") {
    margin-bottom: 28px;
    max-width: 455px;
  }
  @include media("<=550px") {
    font-size: 15px;
    margin-bottom: 24px;
    max-width: 370px;
  }
    @include media("<=350px") {
      font-size: 13px;
    }
  span {
    color: #fff;
    font-weight: 900;
    text-transform: uppercase;
  }
}

.hero__text {
  color: #FFFFFF;
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 22px;
  opacity: 0;
  transition: all 0.8s ease 0.6s;
  
    &._active {
      opacity: 1;
    }
  @include media("<=810px") {
    margin-bottom: 0;
  }
  @include media("<=430px") {
    font-size: 16px;
  }
    @include media("<=350px") {
      font-size: 14px;
    }
}

.button {
  font-family: 'Integral CF';
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 32px;
  color: #000;
  background-color: transparent;
  font-size: 24px;
  font-weight: 500;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  padding: 32px;
  max-width: 420px;
  width: 100%;
  border-radius: 62px;
    border: 1px solid #000;
  transition: all ease-in-out 0.3s;
  position: relative;
  z-index: 25;
  &:hover {
    background-color: #000;
    color: #fff;
  }
    @include media("<=1440px") {
      padding: 26px;
      max-width: 340px;
      font-size: 20px;
    }
        @include media("<=810px") {
          max-width: 400px;
          column-gap: 22px;
        }
    @include media("<=430px") {
      padding: 22px;
      max-width: 340px;
      column-gap: 18px;
    }
        @include media("<=375px") {
          padding: 16px;
          max-width: 260px;
          font-size: 16px;
          column-gap: 18px;
        }
  &-footer {
    border: 1px solid #fff;
    color: #fff;
    @include media("<=810px") {
      font-size: 14px;
      padding: 20px;
    }
        @include media("<=430px") {
          padding: 15px;
        }
  }  
  
}

.button-footer:hover {
  background-color: #fff;
  color: #240CF2;
}

.button:hover .arrow__icon {
  background: #fff;
}

.button:hover .contact__icon {
  background: #240CF2;
}



.arrow__icon {
  position: relative;
  top: 2px;
  display: inline-block;
  height: 24px;
  width: 24px;
  mask: url(../images/arrow.svg);
  -webkit-mask: url(../images/arrow.svg);
  background: #000;
  mask-size: cover;
  transition: all 0.3s ease;
}

.contact__icon {
  position: relative;
  top: 2px;
  display: inline-block;
  height: 24px;
  width: 24px;
  mask: url(../images/arrow.svg);
  -webkit-mask: url(../images/arrow.svg);
  background: #fff;
  mask-size: cover;
  transition: all 0.3s ease;
}

.marquee {
  position: absolute;
  bottom: 5%;
  left: 50%;
  overflow: hidden;
  user-select: none;
  display: flex;
  gap: 15px;
  transform: translateX(-50%) rotate(-5deg);
  background-color: #240CF2;
  padding: 24px 0;
  z-index: 20;
  width: 110%;
  @include media("<=1440px") {
    bottom: 8%;
  }
    @include media("<=1080px") {
      bottom: 7%;
      left: 42%;
      width: 118%;
    }
  @include media("<=810px") {
    padding: 15px 0;
  }
    @include media("<=550px") {
      padding: 8px 0;
      bottom: 8%;
      left: 30%;
      width: 155%;
    }
    @include media("<=360px") {
      bottom: 10%;
    }
  &-green {
    transform: translateX(-50%) rotate(5deg);
    background-color: #0CF223;
    justify-content: flex-end;
    z-index: 19;
    ul {
      animation: marqueegreen 26s linear infinite;
    }
  }
}

ul {
  list-style: none;
  flex-shrink: 0;
  min-width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  animation: marquee 26s linear infinite;
  li {
    display: flex;
    column-gap: 15px;
  }
}



.text {
  font-family: 'Integral CF';
  color: #fff;
  font-weight: 400;
  font-size: 36px;
  text-transform: uppercase;
  @include media("<=1440px") {
   font-size: 32px;
  }
    @include media("<=810px") {
      font-size: 24px;
    }
        @include media("<=550px") {
          font-size: 20px;
        }
}

@keyframes marquee {
  to {
    transform: translateX(calc(-100% - 15px));
  }
}

@keyframes marqueegreen {
  to {
    transform: translateX(calc(100% - 15px));
  }
}



