body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  background-color: #DCE4F5;
  color: #fff;
  position: relative;
  @include media("<=810px") {
    
  }
  @include media("<=430px") {
    
  }
  &.page {
   
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

.container {
  width: 100%;
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
  &-big {
    max-width: 1470px;
    @include media("<=1440px") {
      max-width: 1054px;
    }
  }
}

.wrapper {
  
}

.pic {
  position: absolute;
  z-index: 24;
  &-1 {
    top: 11%;
    right: -30%;
    max-width: 18vw;
    @include media("<=1080px") {
      right: -20%;
    }
        @include media("<=810px") {
          top: -18%;
          right: -18%;
          max-width: 30vw;
        }
             @include media("<=430px") {
              right: -12%;
              max-width: 34vw;
             }
  }
  &-2 {
    left: -32%;
    bottom: 10%;
    max-width: 14vw;  
    @include media("<=1440px") {
      left: -24%;
    }
        @include media("<=1080px") {
          bottom: 38%;
        }
        @include media("<=810px") {
          top: -8%;
          left: -25%;
          max-width: 32vw;
        }
                @include media("<=430px") {
                  left: -18%;
                  max-width: 36vw;
                }
  }
  &-3 {
    right: -16%;
      max-width: 18vw;
      bottom: 18%;
      @include media("<=810px") {
        max-width: 32vw;
        bottom: 20%;
        right: -10%;
      }
  }
}
