.header {
  width: 100%;
  position: relative;
  @include media("<=810px") {
    padding-top: 16px;
    background-color: #0CF223;
  }
  @include media("<=430px") {
    
  }
 
}

.header__inner {
  
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 20;
  @include media("<=1080px") {
      max-width: 864px;
      margin: 0 auto;
    }
  @include media("<=810px") {
    column-gap: 0;
  }
}



.logo {
  display: block;
  max-width: 186px;
  width: 100%;
  flex: 0 1 100%;
  position: absolute;
  right: 0;
  top: 74px;
  @include media("<=1440px") {
    top: 52px;
    max-width: 168px;
  }
    @include media("<=1080px") {
      top: 59px;
      max-width: 144px;
    }
  @include media("<=810px") {
    position: relative;
    z-index: 21;
    max-width: 122px;
    top: auto;
    right: auto;
  }
    @include media("<=430px") {
      max-width: 95px;
    }
  img {
    width: 100%;
    display: block;
  }
}

