@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
    font-family: 'Integral CF';
    src: url('../fonts/IntegralCF-ExtraBold.eot');
    src: url('../fonts/IntegralCF-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/IntegralCF-ExtraBold.woff') format('woff'),
        url('../fonts/IntegralCF-ExtraBold.ttf') format('truetype'),
        url('../fonts/IntegralCF-ExtraBold.svg#IntegralCF-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Integral CF';
    src: url('../fonts/IntegralCF-Medium.eot');
    src: url('../fonts/IntegralCF-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/IntegralCF-Medium.woff') format('woff'),
        url('../fonts/IntegralCF-Medium.ttf') format('truetype'),
        url('../fonts/IntegralCF-Medium.svg#IntegralCF-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Integral CF';
    src: url('../fonts/IntegralCF-Regular.eot');
    src: url('../fonts/IntegralCF-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/IntegralCF-Regular.woff') format('woff'),
        url('../fonts/IntegralCF-Regular.ttf') format('truetype'),
        url('../fonts/IntegralCF-Regular.svg#IntegralCF-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}