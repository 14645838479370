.footer {
  overflow: hidden;
  padding: 467px 0 0;
  margin-top: -457px;
  background-image: url(../images/wrapper-bcg.png);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    position: relative;
  @include media("<=430px") {
    
  }
  
}

.footer__inner {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  column-gap: 150px;
  @include media("<=1440px") {
    column-gap: 105px;
  }
  @include media("<=1080px") {
    column-gap: 60px;
  }
  @include media("<=810px") {
    align-items: center;
    column-gap: 15px;
    justify-content: space-around;
  }
  @include media("<=430px") {
    
  }
    @include media("<=375px") {
      flex-direction: column;
      align-items: center;
    }
}

.footer__content {
  padding-top: 140px;
  display: flex;
  flex-direction: column;
  max-width: 519px;
  width: 100%;
  justify-content: center;
  @include media("<=1440px") {
    max-width: 448px;
    padding-top: 120px;
  }
    @include media("<=1080px") {
      padding-top: 75px;
    }
  @include media("<=810px") {
    padding-top: 0;
    max-width: 340px;
  }
    @include media("<=430px") {
      
    }
    @include media("<=375px") {
      align-items: center;
      text-align: center;
      margin-bottom: 5px;
    }
}

.footer__title {
  font-family: 'Integral CF';
  font-size: 56px;
  font-weight: 500;
  margin-bottom: 24px;
  @include media("<=1440px") {
    font-size: 48px;
  }
  @include media("<=810px") {
    font-size: 36px;
  }
  @include media("<=500px") {
    font-size: 24px;
  }
@include media("<=350px") {
  font-size: 16px;
}
}

.footer__text {
  font-size: 28px;
  margin-bottom: 60px;
  font-weight: 500;
  @include media("<=1440px") {
    font-size: 24px;
    margin-bottom: 40px;
  }
    @include media("<=810px") {
      font-size: 20px;
    }
    @include media("<=500px") {
      font-size: 14px;
    }
}

.footer__image {
  max-width: 415px;
  width: 100%;
  margin-bottom: -200px;
  @include media("<=1440px") {
    max-width: 330px;
    margin-bottom: -180px;
  }
    @include media("<=1080px") {
      max-width: 280px;
      margin-bottom: -135px;
    }
        @include media("<=810px") {
          margin-bottom: -20px;
        }
    @include media("<=430px") {
      max-width: 166px;
    }
        @include media("<=375px") {
          max-width: 175px;
        }
  img {
    display: block;
    width: 100%;
  }
}
